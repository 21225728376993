<template>
  <div>
    <!--begin::User-->
    <div>
          <h3 class="card-label">
            {{ $t('module_data_access.module_data_access') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('module_data_access.module') }}<span class="text-danger">*</span></label>
                <select id="module" v-model="data.module" class="custom-select">
                  <option v-for="(row_data, index) in modules_list" :key="index" :value="row_data.slug">{{row_data.name}}</option>
                </select>
                <!--              <multiselect v-model="module"-->
                <!--                           :placeholder="$t('module_data_access.module')"-->
                <!--                           label="title"-->
                <!--                           track-by="id"-->
                <!--                           :options="modules_list"-->
                <!--                           :multiple="false"-->
                <!--                           :taggable="false"-->
                <!--                           :show-labels="false"-->
                <!--                           :show-no-options="false"-->
                <!--                           :show-no-results="false"-->
                <!--                           @input="selectData">-->
                <!--              </multiselect>-->
                <span v-if="validation && validation.module" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.module[0] }}
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_active"
                                 :class="validation && validation.is_active ? 'is-invalid' : ''" name="check-button"
                                 switch>
                  {{ $t('status') }}
                </b-form-checkbox>
                <span v-if="validation && validation.is_active" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.is_active[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('module_data_access.notes') }}</label>
                <textarea v-model="data.notes" class="form-control"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>




          <div class="row mt-5">
            <div class="col-md-12">
              <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803" style="height: 45px;">
                <h6 class="my-auto text-white">{{ $t('module_data_access.attributes') }}</h6>
                <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{ $t('add_more') }}
                </button>
              </div>
            </div>
            <div class="col-md-12">
              <div class="bg-white">
                <table class="table table-row-bordered" @keyup.alt.enter="addItemRowToList"
                       @keyup.alt.46="removeItemRowFromList(0)">
                  <thead>
                  <tr>
                    <th>{{ $t('module_data_access.attribute_name') }}</th>
                    <th>{{ $t('module_data_access.is_mandatory') }}</th>
                    <th>{{ $t('status') }}</th>
                    <th></th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in details" :key="index">
                    <td>
                      <select v-model="row.name" class="custom-select">
                        <option v-for="(row_data, index_2) in modules_attributes_list"  :key="index_2" :value="row_data.id">{{row_data.title}}</option>
                      </select>
                      <!--                    <multiselect v-model="row.name"-->
                      <!--                                 :placeholder="$t('module_data_access.attribute_name')"-->
                      <!--                                 label="title"-->
                      <!--                                 track-by="id"-->
                      <!--                                 :options="modules_attributes_list"-->
                      <!--                                 :multiple="false"-->
                      <!--                                 :taggable="false"-->
                      <!--                                 :show-labels="false"-->
                      <!--                                 :show-no-options="false"-->
                      <!--                                 @input="selectColumn(index)"-->
                      <!--                                 :show-no-results="false">-->
                      <!--                    </multiselect>-->

                    </td>

                    <td>
                      <b-form-checkbox size="lg" v-model="row.is_mandatory" name="check-button" switch></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
                    </td>
                    <td>
                      <v-icon style="color: #dc3545;" small v-if="details.length > 1" @click="removeItemRowFromList(index)">mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>



    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-module-data-access",
  data() {
    return {
      mainRoute: 'settings/modules_setting',
      mainRouteDependency: 'base/dependency',

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        module: null,
        notes: null,
        is_active: true,
      },
      isEditing: false,
      validation: null,
      details: [],

      details_form: {
        id: null,
        name: null,
        is_mandatory: false,
        is_active: true,
      },

      module: null,
      modules_list: [],
      modules_attributes_list: [],
    };
  },

  watch: {

  },
  methods: {
    save() {
        if (this.isEditing) {
          this.update();
        } else {
          this.create();
        }

    },

    create() {
      let _details = this.details.filter((row) => row.name);
      ApiService.post(this.mainRoute, {
        ...this.data,
        details: _details,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/module-data-access');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      let _details = this.details.filter((row) => row.name);
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        details: _details,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/module-data-access');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.module = response.data.data.module;
        this.data.notes = response.data.data.notes;
        this.data.is_active = (response.data.data.is_active ? true : false);
        // this.module = response.data.data.module;

        if (response.data.data.details) {
          this.details = response.data.data.details;
        } else {
          this.addItemRowToList();
        }
      });
    },

    getModulesList() {
      ApiService.get(this.mainRouteDependency + "/modules").then((response) => {
        this.modules_list = response.data.data;
      });
    },
    getModulesAttributesList() {
      ApiService.get(this.mainRouteDependency + "/modules_attributes").then((response) => {
        this.modules_attributes_list = response.data.data;
      });
    },

    addItemRowToList() {
      this.details.unshift(this.details_form);
      this.details_form = {
        id: null,
        name: null,
        is_mandatory: false,
        is_active: true,
      };

    },
    removeItemRowFromList(index) {
      if (this.details.length > 1) {
        this.details.splice(index, 1);
      }
    },
    loadOptions() {
    },
    selectColumn(index) {//column_name
      this.details[index]['column_name'] = this.details[index]['column'];
    },
    selectData(event) {
      this.data.module = event.id;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.module_data_access"),
      route: '/settings/module-data-access'
    }, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getModulesList();
    this.getModulesAttributesList();

    if (this.idEdit) {
      this.getData();
    } else {
      this.addItemRowToList();
    }

  },
};
</script>


